import { SelectAttentionLeads } from "./../../../ngrx/session/session.selectors";
import {
  ContactNumber,
  ContactEmail,
  Lead,
  ContactAddress,
} from "./../../../classes/lead/lead";
import { LeadsService } from "src/app/services/data/leads/leads.service";
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "src/app/ngrx";
import { Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import * as SessionActions from "src/app/ngrx/session/session.actions";
import * as fromSessionSelectors from "src/app/ngrx/session/session.selectors";
import { Observable, Subject } from "rxjs";
import {
  distinctUntilChanged,
  takeUntil,
  first,
  throttleTime,
} from "rxjs/operators";
import { ModalController, NavController } from "@ionic/angular";
import { Campaign } from "src/app/classes/campaign/campaign";
import { User } from "src/app/classes/user/user";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.page.html",
  styleUrls: ["./detail.page.scss"],
})
export class DetailPage implements OnInit, OnDestroy {
  // tslint:disable-next-line:variable-name
  public _lead: Lead;
  // tslint:disable-next-line:variable-name
  public _phone: ContactNumber;
  // tslint:disable-next-line:variable-name
  public _email: ContactEmail;
  public _address: ContactAddress;
  public backUrl: string;
  // tslint:disable-next-line: variable-name
  public _campaigns: Campaign[];
  public campaignId: string;

  
  public user: User;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private updates$: Actions,
    private navCtrl: NavController,
    private modalController: ModalController
  ) {
    this._lead = new Lead();
  }

  ngOnInit() {
    this.backUrl = "/app/leads";
    const leadId = this.activatedRoute.snapshot.paramMap.get("id");
    this.store
      .select(fromSessionSelectors.SelectLeads)
      .pipe(distinctUntilChanged())
      .subscribe((leads) => {
        const tmp = leads.find(({ id }) => leadId === id);
        if (tmp != null) {
          this._lead = new Lead(tmp);
          //this._lead.attention = Boolean(Number(this._lead.attention));
          //this._phone = new ContactNumber(this._lead.contactPhone);
          //this._email = new ContactEmail(this._lead.contactEmail);
        }
      });
    this.store
      .select(fromSessionSelectors.SelectPipeline)
      .pipe(distinctUntilChanged())
      .subscribe((leads) => {
        const tmp = leads.find(({ id }) => leadId === id);
        if (tmp != null) {
          this._lead = new Lead(tmp);
          //this._lead.attention = Boolean(Number(this._lead.attention));
          
          this.backUrl = "/app/pipeline";
        }
      });
    this._email = new ContactEmail(this._lead.contactEmail);
    this._phone = new ContactNumber(this._lead.contactPhone);

    this.store
      .select(fromSessionSelectors.SelectCampaigns)
      .pipe(first())
      .subscribe((campaigns) => {
        this._campaigns = campaigns;
      });
      
    this.store.select(fromSessionSelectors.SelectCurrentUser).pipe(takeUntil(this.ngUnsubscribe)).subscribe(u => this.user = u);
    
    //this.store.dispatch(SessionActions.GetCampaigns());
    
    //console.log(JSON.stringify(this._campaigns));
  }

  ionViewWillEnter() {
    /* this.store.dispatch(
      SessionActions.ChangeTopBar({
        pagedata: {
          backUrl: this.backUrl,
          displayback: true,
          title: this._lead.first_name + " " + this._lead.last_name,
          displayfilter: false,
          displaycalendar: false,
        },
      })
    );

    
    ); */
    // this.store.dispatch(SessionActions.DisplayLead({ lead: this._lead }));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener("window:popstate", ["$event"])
  dismiss() {
    this.store.dispatch(SessionActions.DisplayLead({ lead: null }));
    this.modalController.dismiss({callNext:false, lead: this._lead});
  }

  async updateLead(callNext?:any) {
    if(this._lead.id == undefined){
      this._lead.phone_number = this._phone.phonenumber;
      this._lead.email = this._email.email;
      this._lead.assigned_user_id = this.user.id;
      this.store.dispatch(
        SessionActions.CreateLead({
          campaign: this.campaignId,
          lead: this._lead
        })
      );
    }
    else{
      this.store.dispatch(
      SessionActions.UpdateLead({
        lead: this._lead,
        phone: this._phone,
        email: this._email,
        address: this._address,
      })
    );
    }
    this.updates$
      .pipe(ofType(SessionActions.CreateLeadSuccess), first())
      .subscribe((action) => {
        this.store.dispatch(SessionActions.DisplayLead({ lead: action.lead }));
        this.modalController.dismiss({callNext, lead: action.lead});
      }); 
    
  }
}
