<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="primary" size="large">
        <ion-icon name="chevron-back-outline">
        </ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center" color="primary">
      New Lead
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form (ngSubmit)="updateLead()">
    <ion-list lines="full" class="ion-no-margin ion-no-padding">
      <ion-item-divider lines="none">
        <ion-label color="primary">
          Contact Details
        </ion-label>
        <ion-icon name="person-outline" color="primary" slot="end" size="small"></ion-icon>
      </ion-item-divider>
      <ion-item>
        <ion-label position="floating">First Name <ion-text color="danger">*</ion-text>
        </ion-label>
        <ion-input required type="text" [(ngModel)]="_lead.first_name" name="first_name" aria-label="first name"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Last Name <ion-text color="danger">*</ion-text>
        </ion-label>
        <ion-input required type="text" [(ngModel)]="_lead.last_name" name="last_name" aria-label="last name"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Phone Number</ion-label>
        <ion-input type="tel" name="phonenumber" aria-label="phonenumber" [(ngModel)]="_phone.phonenumber"> </ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Email</ion-label>
        <ion-input type="email" name="email" aria-label="email" [(ngModel)]="_email.email"></ion-input>
      </ion-item>

      
      <ion-item>
        <ion-label position="floating">Source Campaign</ion-label>
        <ion-select name="campaign"  aria-label="campaign" [(ngModel)]="campaignId">
          <ion-select-option *ngFor="let campaign of _campaigns" value="{{campaign.id}}">{{campaign.name}}</ion-select-option>
        </ion-select>
      </ion-item>

    </ion-list>
    <ion-grid>
			<ion-row>
				<ion-col  class="ion-text-center">
      <ion-button expand="block" type="submit" color="primary" fill="outline">
        <ion-icon name="save-outline" slot="start"></ion-icon>Save
      </ion-button>
      </ion-col>
      <ion-col>
      <ion-button expand="block" fill="outline" (click)="updateLead(true)" color="success">
        <ion-icon name="call-outline" slot="start"></ion-icon>Call
      </ion-button>
    </ion-col>
    </ion-row>
    </ion-grid>

  </form>
</ion-content>